@import url("/node_modules/bootstrap/dist/css/bootstrap.min.css");

body {
  margin: 0;
  color: #3c3c3b;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

header {
  margin: 25px 0;
}

.header_full_container {
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.py-5 {
  border-top: 3px solid #000000;
  border-bottom: 1px solid #000000;
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header_wrapper > div {
  flex: 0 0 auto;
}

.header_logo_section {
  display: flex;
}

.header_logo_section > img {
  width: 90px;
  height: auto;
  object-fit: contain;
}

.header_logo_section_title {
  font-weight: 600;
  font-size: 1rem;
  margin-left: 10px;
  margin-bottom: 0;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}

.header_adress {
  flex: 0 0 auto;
}

.header_adress > span {
  margin-left: 16px;
}

.header_adress > span:last-child {
  display: none;
}

.header_adress > span:hover {
  text-decoration: underline;
}

.header_phone {
  margin: 15px;
}

.header_phone > a {
  font-weight: 700;
  margin-left: 16px;
}

.header_bottom {
  width: 100%;
}

.header_menu {
  height: 62px;
}

.header_burger {
  display: none;
  cursor: pointer;
}

.header_burger > div {
  width: 22px;
  height: 0;
  margin: 5px 0;
  border: 1px solid #000;
}

.main_menu {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.main_menu_list {
  display: flex;
  padding: 0;
  list-style: none;
  width: auto;
  margin: 0 -15px;
  justify-content: space-around;
}

.main_menu_item {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

/* first screen */
.first_screen {
  background-image: url(/src/img/first_screen_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  padding: 0px;
}

.first_screen_top {
  background-color: rgba(34, 34, 34, 0.6);
  padding-bottom: 20px;
}

.first_screen_heading {
  font-size: 2vw;
  padding-top: 20px;
  padding-bottom: 20px;
}

.first_screen_advantage_item {
  width: 25%;
  display: flex;
  align-items: center;
}

.first_screen_advantage_item > span {
  font-size: 0.8rem;
  display: block;
}

.first_screen_advantage_item > img {
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.first_screen_bigbtn {
  margin: 300px 0 0px 10px;
  padding-bottom: 20px;
}

.first_screen_mobile_title,
.first_screen_mobile_advantages {
  background: rgba(5, 20, 31, 0.9);
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.first_screen_mobile_title {
  font-size: 0.7rem;
  padding: 10px 0;
  text-align: center;
}

.mobile_photo {
  width: 100%;
}

.first_screen_mobile_advantages {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 10px;
}

.first_screen_mobile_advantages > div {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 2.5vw;
  height: 3em;
}

.first_screen_mobile_advantages > div > img {
  width: 1.4em;
  height: 1.4em;
}

.first_screen_mobile_advantages > div > span {
  margin-left: 10px;
}

.timer_section_bg {
  background-color: #cf0000;
  background-image: url(/src/img/timer_percent.png);
  background-position: 80vw center;
  background-repeat: no-repeat;
  background-size: 11rem;
  color: #fff;
}

.timer_wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.timer_wrapper > div {
  height: 35%;
}

.timer {
  display: flex;
  margin-right: 50px;
}

.timer_item {
  text-align: center;
  margin: 0 3px;
  flex-basis: calc(100% / 3 - 20px);
}

.timer_name {
  font-size: 0.75rem;
}

.timer_value {
  font-size: 36px;
  background: #ffffff;
  color: #333;
  margin-top: 8px;
  padding: 0px 40px;
  letter-spacing: 0.1em;
}

.form_comment {
  margin-top: 64px;
  font-size: 12px;
  color: rgba(60, 60, 59, 0.8);
  display: none;
}

.form_comment > a {
  text-decoration: underline;
}

/* post list */
.post_list {
  background: #f2f3f3;
  padding-bottom: 50px;
}

.post_title {
  font-size: 24px;
  font-weight: 700;
}

.post_desc {
  margin-left: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 14px;
}

.post_advantages {
  display: block;
}

.post_advantages_item {
  margin-bottom: 0.4rem;
  width: 100%;
}

.post_advantages_item > img {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.5rem;
}

.post_price_desc {
  margin-top: 20px;
}

.post_price_desc > p {
  margin-left: 28px;
  margin-bottom: 0;
  font-weight: 700;
}

.post_price_desc > button {
  width: 100%;
  margin-top: 10px;
}

/*slider*/
.all_swiper_wrapper {
  width: 60%;
  display: flex;
}

.thumb_swiper {
  width: 54px !important;
  height: 100%;
  margin: 0 !important;
}

.thumb_swiper > .swiper-wrapper {
  width: 50px;
  height: 100%;
  flex-direction: column;
}

.thumb_swiper > .swiper-wrapper > .swiper-slide {
  width: 50px !important;
  height: 50px !important;
  margin: 5px 0;
}

.thumb_swiper > .swiper-wrapper > .swiper-slide-thumb-active {
  border: 2px solid #000;
}

.thumb_swiper > .swiper-wrapper > .swiper-slide > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center center;
}

.main_swiper {
  margin-left: 10px !important;
}

.main_swiper > .swiper-wrapper > .swiper-slide > img {
  width: 100% !important;
  object-fit: contain;
  object-position: top left;
  display: block;
  margin: 0 !important;
}

.main_swiper > .swiper-wrapper {
  width: 500px;
}

/*Top reasons*/

.reasons {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
}

.reasons > div {
  flex-basis: 20%;
  margin-bottom: 15px;
}

.reasons_gifs_wrapper {
  width: 80px;
  height: 80px;
  border: 2px solid #222;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 10px;
}

.reasons_gifs {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

/*Choose equipment*/

.choose_car_equipment {
  border-bottom: 1px solid #ccc;
}

.choose_first_block {
  display: flex;
}

.choose_equipment > h3 {
  font-size: 2.75rem;
  font-weight: 400;
}

.choose_selects {
  width: 100%;
  padding: 12px;
}

.choose_select_wrapper {
  width: 50%;
  padding: 10px;
}

.choose_car_equipment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.choose_car_img {
  width: 285px;
  height: 285px;
  object-fit: contain;
}

.choose_btns > button {
  width: 200px;
  display: block;
  margin: 15px;
}

.choose_car_title {
  font-weight: 700;
}

.choose_show {
  cursor: pointer;
  text-align: center;
  padding: 20px 0;
  font-weight: 700;
}

/*offer inputs*/
.offer_input {
  background-image: url(img/offer_input_bg.jpg);
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding: 50px 0;
}

.offer_input_block > div > input {
  min-width: 40%;
}

.privacy_policy {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #fff;
}

.privacy_policy > a {
  text-decoration: underline;
}

.offer_input_title {
  text-shadow: 0 0 4px black;
  font-size: 2rem;
  max-width: 50%;
  margin-left: 10px;
}

.offer_input_subtitle {
  text-shadow: 0 0 4px black;
  margin-top: 15px;
  margin-left: 10px;
  max-width: 50%;
  font-size: 1rem;
}

.gifts_title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 50px;
}

.gifts_list {
  display: flex;
  justify-content: space-between;
}

.gifts_item {
  padding: 20px 22px;
  width: 25%;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.gifts_item:hover {
  border: 2px solid #524f4f;
  background-color: #fff;
}

.gifts_item > img {
  width: 51px;
  height: auto;
  margin-right: 15px;
}

.gifts_item > span {
  font-size: 20px;
  display: block;
}

/*Credit section*/
.credit_section {
  background-image: url(img/credit_section_bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 64px 0;
}

.credit_section_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.credit_section_container > section {
  max-width: 680px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 32px;
}

.credit_section_title {
  font-size: 1.75rem;
}

.credit_section_wrapper {
  display: flex;
}

.credit_section_item_first {
  width: 60%;
}

.credit_section_item_first > p:first-child {
  font-size: 20px;
  margin-bottom: 15px;
}

.credit_section_item_first > ul {
  padding-left: 1rem;
  font-size: 14px;
}

.credit_section_item_second {
  width: 40%;
  font-size: 20px;
}

.credit_section_subtitle {
  font-size: 14px;
}

.credit_section_inputs {
  display: flex;
}

.credit_section_item_first > p {
  margin: 0;
}

.credit_section_item_second > p {
  margin: 0;
}

.credit_section_subtitle {
  margin-top: 15px;
}

/*Contacts*/

.contacts_title {
  font-size: 2.75rem;
  text-align: center;
  margin-bottom: 40px;
}

.contacts_list {
  width: 35%;
}

.contacts_item > p {
  margin: 0;
  font-size: 14px;
}

.contacts_item > p:first-child {
  font-weight: bold;
}

.requisites {
  padding-bottom: 30px;
  border-bottom: 2px solid #222;
}

.contacts_item {
  margin-bottom: 20px;
}

.contacts_map {
  width: 65%;
}

.map {
  width: 100% !important;
  height: 100% !important;
  margin-left: 30px;
}

/*Footer*/
footer {
  background-color: #1f1f1f;
  font-size: 12px;
  line-height: 1.6rem;
}

.footer_info {
  color: rgba(255, 255, 255, 0.5);
}

.footer_privacy {
  color: #fff;
}

.footer_privacy > a {
  display: block;
}

.footer_privacy > a:hover {
  text-decoration: underline;
}

.main_menu_list.mobile {
  display: block;
  margin: 0;
  width: calc(100% - 45px);
  margin-bottom: 15px;
  margin-top: 30px;
}

.main_menu_list.mobile > li:hover {
  text-decoration: underline;
}

.main_menu_list.mobile > li {
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.burger_menu_wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.burger_menu_wrapper.open {
  opacity: 1;
  visibility: visible;
}

.burger_menu {
  position: absolute;
  width: 70%;
  height: 100%;
  right: -100%;
  top: 0;
  background-color: #fff;
  transition: all 0.3s ease;
}

.burger_menu.open {
  right: 0;
}

.burger_menu > div {
  padding: 15px;
}

.burger_menu > div > button {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: none;
  font-size: 20px;
}

.burger_menu > div > a {
  font-weight: 700;
}

.flag-dropdown {
  display: none !important;
}

.react-tel-input .form-control {
  position: initial;
  font-size: initial;
  letter-spacing: initial;
  margin-top: initial;
  margin-bottom: initial;
  padding-left: initial;
  margin-left: initial;
  background: initial;
  border: initial;
  border-radius: initial;
  line-height: initial;
  height: initial;
  width: initial;
  outline: initial;
}

.inputDef {
  display: flex;
  font-size: 1rem;
  padding: 10px;
  height: 100%;
}

.inputContainer {
  width: 100%;
}

.padding-0 {
  padding: 0 !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-spinner {
  animation: rotation 2s infinite linear;
}

/* Modal */
.modal_bg {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.modal_bg.opened {
  opacity: 1;
  visibility: visible;
}

.modal_bg_container {
  display: block;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  font-family: "Roboto", sans-serif;
}

.modal_bg_container > h3 {
  font-weight: 700;
}

.modal_bg_container > p {
  font-size: 20px;
}

.modal_bg_container > button {
  padding: 15px 40px;
  background-color: #222;
  border: none;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.modal_bg_container > button:hover {
  color: #222;
  background-color: #ccc;
}
